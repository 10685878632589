import { StrictMode, Suspense } from "react";

import "@vericus/cadmus-common/dist/style.css";
import { CssBaseline, CUIProvider } from "@vericus/cadmus-ui";
import "@vericus/cadmus-ui/dist/index.css";

import { ApolloProvider } from "@apollo/client/react";
import NiceModal from "@ebay/nice-modal-react";
import { JitsuProvider } from "@jitsu/react";
import * as Sentry from "@sentry/react";
import "core-js/features/array/flat";
import "core-js/features/string/match-all";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";

import { client as apolloClient } from "@/client/apollo";
import jitsuClient from "@/client/jitsu";
import { CrashError } from "@/components/errors";
import { LoadingPage } from "@/components/loading-page";
import reduxStore from "@/data/store";

import { theme } from "./index.css";
import "./instrument";
import { routes } from "./routes";
import { MantineProvider } from "./styles/mantine";

import "@mantine/core/styles.css";
import "@mantine/dates/styles.css";
import "mathlive/dist/mathlive-static.css";

const container = document.getElementById("root")!;
const root = createRoot(container);

root.render(
  <StrictMode>
    <CUIProvider theme={theme}>
      <Sentry.ErrorBoundary fallback={<CrashError />}>
        <JitsuProvider client={jitsuClient}>
          <NiceModal.Provider>
            <MantineProvider>
              <Provider store={reduxStore}>
                <ApolloProvider client={apolloClient}>
                  <>
                    <CssBaseline />
                    <BrowserRouter>
                      <Suspense fallback={<LoadingPage />}>{routes}</Suspense>
                    </BrowserRouter>
                  </>
                </ApolloProvider>
              </Provider>
            </MantineProvider>
          </NiceModal.Provider>
        </JitsuProvider>
      </Sentry.ErrorBoundary>
    </CUIProvider>
  </StrictMode>
);

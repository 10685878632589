import { loadDevMessages, loadErrorMessages } from "@apollo/client/dev";
import * as Sentry from "@sentry/react";

import { DEPLOY_ENV, DEV, RELEASE_HASH, SENTRY_DSN } from "./config";

//////////////////////////////////////////////////////////////////////////////
// Apollo                                                                   //
//////////////////////////////////////////////////////////////////////////////

if (DEV) {
  loadDevMessages();
  loadErrorMessages();
}

//////////////////////////////////////////////////////////////////////////////
// Sentry                                                                   //
//////////////////////////////////////////////////////////////////////////////

Sentry.init({
  dsn: SENTRY_DSN,
  environment: DEPLOY_ENV,
  release: RELEASE_HASH,
  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 0.1,
  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: 0.8,
  integrations: [
    Sentry.replayIntegration({
      maskAllText: true,
      blockAllMedia: true,
    }),
    Sentry.browserTracingIntegration(),
  ],
  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 0.8,
  ignoreErrors: ["mce-visual-caret-hidden"],
});
